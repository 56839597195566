<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlGzjgglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" :incomeTreeData="true" :receiveTreedata="orgsTree" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @handleSelectionChange="handleSelectionChange" :hasSelection="true"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加弹窗 -->
    <el-dialog  class="dialog-mini" width="500px" :title="addTitle" :visible.sync="FormDialog">
      <el-form :rules="rules" ref="dataForm" :model="addform" label-position="right" label-width="100px">
        <el-form-item size="small" :label="'名称'" prop="name">
          <el-input v-model="addform.name" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" :label="'状态'">
          <el-select class="filter-item" v-model="addform.enable" placeholder="请选择状态">
            <el-option v-for="item in  statusOptions" :key="item.key" :label="item.display_name" :value="item.key"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item size="small" :label="'上级机构'">
          <treeselect ref="orgsTree" :options="mechOrgsTree" :default-expand-level="3" :multiple="false" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" v-model="selectOrgs"></treeselect>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="FormDialog = false">取消</el-button>
        <el-button size="mini" v-if="dialogStatus=='create'" type="primary" @click="createData">确认</el-button>
        <el-button size="mini" v-else type="primary" @click="updateData">确认</el-button>
      </div>
    </el-dialog>

    

    <!-- 分配用户 -->
    <distributionUser :userDialog="userDialog" :list="usersList" @handleDistributionUser="handleDistributionUser" @cancelUserDialog="cancelUserDialog"></distributionUser>
    
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import * as users from '@/api/users'//获取用户接口
  import {listToTreeSelect} from '@/utils'
  import * as login from '@/api/login'
  import Treeselect from '@riophae/vue-treeselect'
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      Treeselect,
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          key:'',
          areaName: undefined,
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        FormDialog:false,//添加弹窗
        addform:{//添加表单
          id: undefined,
          parentName: '',
          parentId: null,
          name: '',
          enable: true
        },
        rules: {
          name: [{
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          }]
        },
        
        addTitle:'添加',//添加弹窗标题
        
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        
        mainMultipleSelection:[],//多选数据
        userDialog:false,//用户弹窗
        usersList:[],//所有用户
        
        statusOptions: [
          {
            key: false,
            display_name: '停用'
          },
          {
            key: true,
            display_name: '启用'
          }
        ],
        selectOrgs:'',
        rgsTree: [], // 用户可访问到的所有机构组成的树
        dialogStatus:'create',//新增编辑
        // orgsTree:[],
      }
    },
    watch: {
      selectOrgs() {
        if (this.selectOrgs === undefined || this.selectOrgs === null || this.selectOrgs === '') { // 如果是根节点
          this.addform.parentName = '根节点'
          this.addform.parentId = ''
          this.isRoot = true
          return
        }
        this.isRoot = false
        this.addform.parentId = this.selectOrgs
        var parentname = this.orgs.find((val) => {
          return this.selectOrgs === val.id
        }).label
        this.addform.parentName = parentname
      }
    },
    computed: {
      mechOrgsTree() {
        const arr = [{
          id: '',
          label: '根节点',
          parentId: null
        }]
        return arr.concat(this.orgsTree)
      },
      orgsTree(){
        return this.$store.state.allInstitution
      },
    },
    filters: {
      
    },
    created() {
      this.getList()
      this.getUserData()
    },
    mounted() {
      // this.getOrgTree();//获取tree数据
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      getOrgTree() {
        return
        var _this = this // 记录vuecomponent
        var data = {
          page: 1,
          limit: 9999,
        }
        basicDataApi.jczlFzjgglSearch(data).then(response => {
          _this.orgs = response.data.map(function(item) {
            return {
              id: item.id,
              label: item.name,
              parentId: item.parentId || null
            }
          })
          var orgstmp = JSON.parse(JSON.stringify(_this.orgs))
          _this.orgsTree = listToTreeSelect(orgstmp)
        })
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
        // this.CheckedKey = data
        // var s = this.CheckedKey.length
        // this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.mainMultipleSelection.length >0 ){
              this.handleDelete(this.mainMultipleSelection)
            }else{
              this.$message({
                message: `请选择至少一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'btnAdd'://添加
            this.addTitle = '添加'
            this.dialogStatus = 'create'
            this.FormDialog = true
            this. addform={//添加表单
              id: undefined,
              parentName: '',
              parentId: null,
              name: '',
              enable: true
            }
            break
          case 'btnEdit'://编辑
            if(this.mainMultipleSelection.length == 1){
              this.addTitle = '编辑'
              this.dialogStatus = 'edit'
              this.addform = JSON.parse(JSON.stringify(this.mainMultipleSelection[0]))
             
              this.FormDialog = true
              this.$nextTick(()=>{
                this.selectOrgs = this.addform.parentId
              })
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          
          case 'distributionUser'://分配用户
            if(this.listQuery.TreeIds.length <= 0){
              this.$message({
                message: `请选择左侧树形数据进行分配！`,
                type: 'error'
              })
            }else{
              this.userDialog = true
            }
            // if(this.mainMultipleSelection.length <= 0){
            //   this.$message({
            //     message: `请选择一条数据进行调整！`,
            //     type: 'error'
            //   })
            // }else{
            //   this.userDialog = true
            // }
            break
          default:
            break
        }
      },
      // 获取用户数据
      getUserData(){
        var data={
          page: 1,
          limit: 50
        }
        users.getList(data).then(response => {
          if(response.code == 200){
            this.usersList = response.data
          }
        })
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.jczlFzjgglSearch(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
            this.loading = false

            
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
            this.loading = false
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
        // this.tableData = this.$store.state.tableDataSearch
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      // 多行删除
      handleDelete(row) {
        this.$confirm(`确定要删除选中记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          let data  =[]
          row.forEach((item)=>{
            data.push(item.id)
          })
          basicDataApi.jczlFzjgglDel(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add弹窗提交
      confirmDialog(){
         basicDataApi.qyglAdd(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialog = false
            this.$basicFun.getAllList()//获取全部区域
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialog = false
        })
      },
      
      //表格多选
      handleSelectionChange(val){
        this.mainMultipleSelection = val
      },
      
      // 分配用户保存
      handleDistributionUser(val) {
        // 选择右侧数据逻辑
        // var areaIds = []
        // var ids = []
        // this.mainMultipleSelection.forEach(i=>{
        //   areaIds.push(i.id)
        // })
        // val.forEach(i=>{
        //   ids.push(i.id)
        // })
        // var data = {
        //   areaIds:areaIds,
        //   ids:ids
        // }
        var ids = []
        val.forEach(i=>{
          ids.push(i.id)
        })
        var data = {
          areaIds:this.listQuery.TreeIds,
          ids:ids
        }
        basicDataApi.qyglUserDistribution(data).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.userDialog = false
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          
        })
      },
      //分配用户取消
      cancelUserDialog(val){
        this.userDialog = val
      },
      createData() { // 弹出编辑框
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$store.commit("updataListLoading",true)
            basicDataApi.jczlFzjgglAdd(this.addform).then((response) => {
              this.$notify({
                title: '成功',
                message: response.message,
                type: 'success',
                duration: 2000
              })
              this.FormDialog = false
              this.getList()
              this.upDateFzjg();//更新分支机构数据
              
              this.$store.commit("updataListLoading",false)
            }).catch(()=>{
              this.$store.commit("updataListLoading",false)
            })
          }
        })
      },
      updateData() { // 更新提交
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$store.commit("updataListLoading",true)
            basicDataApi.jczlFzjgglEdit(this.addform).then((response) => {
              this.$notify({
                title: '成功',
                message: response.message,
                type: 'success',
                duration: 2000
              })
              this.FormDialog = false
              this.getList()
              this.upDateFzjg();//更新分支机构数据
              this.$store.commit("updataListLoading",false)
            }).catch(()=>{
              this.$store.commit("updataListLoading",false)
            })
          }
        })
      },
      //更新分支机构数据
      upDateFzjg(){
        var data={
          page: 1,
          limit: 9999,
        }
        basicDataApi.getAllList(data).then(response => {
          if(response.code == 200){
            // 获取所有分支机构处理
            var Process = response.result.HdybArea.map(function(item) {
              return {
                id: item.id,
                label: item.name,
                parentId: item.parentId || null
              }
            })
            var orgstmp = JSON.parse(JSON.stringify(Process)) 
            this.$store.state.allInstitution = listToTreeSelect(orgstmp)
          }
        }).catch(()=>{
          
        })
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
